'use strict';

import {Dispatcher} from 'flux';

const dispatcher = new Dispatcher();

const oldDispatch = dispatcher.dispatch;

// override dispatch method to log all actions
dispatcher.dispatch = function(action) {
    window.setTimeout(() => {
        oldDispatch.call(this, action);
    }, 0);
};

export default dispatcher;